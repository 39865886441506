import { Container, Box } from '@mui/material';
import { Route, Switch } from 'react-router-dom';
import { LoginPage } from '../../features/pages/LoginPage';
import { FollowReportPage } from '../../features/pages/messaging/FollowReportPage';
import PageWithTokens from '../../features/pages/PageWithTokens';
import { Redirect, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Home } from '../../features/pages/home/Home';
import CreateReport from '../../features/pages/createReport/CreateReport';
import { useEffect } from 'react';
import { setStoredTokens } from '../../utils/setStoredTokens';


export const Main = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/follow-report') {
      setStoredTokens()
    }
  }, [location])

  return (
    <Container maxWidth="1440">
      <Box width={'95%'} m="auto" sx={{ pb: '10px', pt: '10xp' }}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/create-report" component={CreateReport} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/report-credentials" component={PageWithTokens} />
          <Route exact path="/follow-report" component={FollowReportPage} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Box>
    </Container>
  )
}
