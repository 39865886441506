import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
  },

  '& .MuiFormHelperText-root': {
    color: '#EA4E4E',
  },

  '& .MuiInput-underline:after': {
    borderBottomColor: 'blue',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Readex Pro',
    fontWeight: 400,
    fontSize: '20px',
    color: `${theme.palette.mainColors.black}`,
    backgroundColor: `${theme.palette.additionalColors.lightGreen}`,
    borderRadius: '10px',
    '&.Mui-error': {
      border: '1px solid #EA4E4E',
      backgroundColor: '#F9D5CC',
      '& ::placeholder': {
        color: '#5C5B53',
      },
      '& fieldset, &:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: `none`,
      },
    },

    '& textarea': {
      resize: 'vertical',
    },
    '& fieldset': {
      border: `1px solid ${theme.palette.additionalColors.stroke}`,
      boxSizing: 'border-box',
      borderRadius: '10px',

    },
    '&:hover fieldset': {
      border: `1px solid ${theme.palette.additionalColors.stroke}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.additionalColors.stroke}`,
    },
  },
}));


export default function CustomizedTextField(props) {
  return (
    <CssTextField
      placeholder='Write Description here...'
      fullWidth
      multiline
      minRows={4}
      {...props}
    />
  )
};

