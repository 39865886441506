import { useEffect, useState } from "react";
import { useReportContext } from "../../../../shared/contexts/reportContext";
import { useStepsContext } from "../../../../shared/contexts/stepsContext";
import { ComplainReport } from "../../../../shared/forms/ComplainReport";
import Confirmation from "../../../../shared/forms/Confirmation";

const Anonymous = ({ setStep }) => {
    const { setReport, report } = useReportContext();
    const { setCurrentStep, setMaxSteps } = useStepsContext();

    const [innerStep, setInnerStep] = useState(2);
    const [attachmentFile, setAttachmentFile] = useState();

    useEffect(() => {
        setCurrentStep(innerStep-1);
    }, [innerStep, setCurrentStep]);

    useEffect(() => {
        setMaxSteps(2);
    }, []);

    const reportStepContent = {
        2: <ComplainReport setStep={setStep} setInnerStep={setInnerStep} setReport={setReport} report={report} setAttachmentFile={setAttachmentFile}/>,
        3: <Confirmation setStep={setStep} setInnerStep={setInnerStep} report={report} attachmentFile={attachmentFile} step={innerStep}/>
    }
    return (
        <>
            {reportStepContent[innerStep]}
        </>
    )
}

export default Anonymous;
