import { Container, FormGroup, Stack, Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';
import { useForm, Controller } from 'react-hook-form';
import CountrySelect from '../basic/CountrySelect';
import CommonInput from '../basic/CommonInput';
import { StyledButton } from '../basic/StyledButton';
import { CustomInput } from '../basic/CustomInput';
import { StyledCheckbox } from '../basic/StyledCheckbox';
import { useWindowResize } from '../../utils/useWindowResize';
import { useTranslation } from 'react-i18next';

const userIdentitySchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string(),
  relationship: yup.string().required(),
  allow_notifications: yup.boolean(),
  data_security: yup.boolean().oneOf([true]).required(),
});

const UserIdentity = ({ setStep, setInnerStep, setReport }) => {
  const [width] = useWindowResize()
  const { t } = useTranslation()

  const errorMessage = {
    first_name: t('userIdentity.inputs.first-name.errors.required'),
    last_name: t('userIdentity.inputs.last-name.errors.required'),
    email: {
      email: t('userIdentity.inputs.email.errors.invalid'),
      required: t('userIdentity.inputs.email.errors.required')
    },
    phone: t('userIdentity.inputs.telephone.errors.invalid'),
    relationship: t('userIdentity.inputs.relation.errors.required')
  }

  const { control, handleSubmit, formState: { errors, isValid }, getValues, setValue, trigger } = useForm({
    resolver: yupResolver(userIdentitySchema),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    const { first_name, last_name, email, relationship, allow_notifications, phone } = data;
    const reporter = {
      reporter: {
        first_name,
        last_name,
        email,
        phone,
        relationship,
        allow_notifications
      }
    };

    setReport((form) => ({
      ...form,
      ...reporter
    }))
    setInnerStep(2)
    return reporter;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        padding: '64px 0',
        borderRadius: '10px',
        boxShadow: '4px 8px 40px rgba(36, 63, 115, 0.08)'
      }}
    >
      <Container maxWidth="md">
        <Box>
          <Typography variant="h2" align='center' sx={{ textAlign: 'center', mb: '64px' }}>{t('userIdentity.title')}</Typography>
          <CustomInput sup={'1 / 5'} name={t('userIdentity.inputs.first-name.title')}>
            <Controller
              render={({ field }) =>
                <CommonInput
                  placeholder={t('userIdentity.inputs.first-name.placeholder')}
                  fullWidth
                  {...field}
                  {...(errors.first_name && { error: true, helperText: errorMessage.first_name })}
                  sx={{ '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } }}
                />
              }
              name="first_name"
              control={control}
              defaultValue=""
            />
          </CustomInput>
          <CustomInput sup={'2 / 5'} name={t('userIdentity.inputs.last-name.title')}>
            <Controller
              render={({ field }) =>
                <CommonInput
                  placeholder={t('userIdentity.inputs.last-name.placeholder')}
                  fullWidth
                  {...field}
                  {...(errors.last_name && { error: true, helperText: errorMessage.last_name })}
                  sx={{ '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } }}
                />
              }
              name="last_name"
              control={control}
              defaultValue=""
            />
          </CustomInput>
          <CustomInput sup={'3 / 5'} name={t('userIdentity.inputs.email.title')}>
            <Controller
              render={({ field }) =>
                <CommonInput
                  placeholder={t('userIdentity.inputs.email.placeholder')}
                  fullWidth {...field}
                  {...(errors.email && { error: true, helperText: errorMessage.email[errors.email.type] })}
                  sx={{ '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } }}
                />}
              name="email"
              control={control}
              defaultValue=""

            />
          </CustomInput>
          <CustomInput sup={'4 / 5'} name={t('userIdentity.inputs.telephone.title')}>
            <Stack direction="row">
              <Controller
                name="codeCountry"
                render={({ field, field: { onChange } }) => {
                    return (
                        <CountrySelect field={{...field, onChange: (e, value) => {
                            onChange({
                                target: {
                                    name: 'codeCountry',
                                    value: value
                                }
                            })
                        }}}/>
                    )
                    }
                }
                control={control}
                defaultValue="+49"
              />

              <Controller
                render={({ field, field: { onChange } }) =>
                  <CommonInput
                    placeholder="000 0000" {...field}
                    onChange={({ target }) => {
                      const currentValue = target.value;
                      const { codeCountry } = getValues();
                      setValue('phone', `${codeCountry}${currentValue}`);
                      trigger('phone');
                      onChange(currentValue);
                    }}
                    fullWidth
                    {...(errors.phone && { error: true, helperText: errorMessage.phone })}
                    sx={
                      width <= 500 ?
                        { '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } } : { marginLeft: '16px' }
                    }
                  />}
                name="numberPhone"
                control={control}
                defaultValue=""
              />
            </Stack>
          </CustomInput>
          <CustomInput sup={'5 / 5'} name={t('userIdentity.inputs.relation.title')}>
            <Controller
              render={({ field }) =>
                <CommonInput
                  id="outlined-basic" placeholder={t('userIdentity.inputs.relation.placeholder')}
                  fullWidth {...field}
                  {...(errors.relationship && { error: true, helperText: errorMessage.relationship })}
                  sx={{ '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } }}
                />}
              name="relationship"
              control={control}
              defaultValue=""

            />
          </CustomInput>

          <Box variant="div">
            <Controller
              name="allow_notifications"
              control={control}
              render={({ field }) =>
                <FormGroup>
                  <StyledCheckbox title={t('userIdentity.inputs.checkbox.notifications')} {...field} />
                </FormGroup>}
            />
            <Controller
              name="data_security"
              control={control}
              render={({ field }) =>
                <StyledCheckbox
                  title={
                    <>
                      {t('userIdentity.inputs.checkbox.datapolicy.text')}&nbsp;
                      <a href={t('userIdentity.inputs.checkbox.datapolicy.link.href')} style={{ color: '#7AA6A3' }} target="_blank">
                        {t('userIdentity.inputs.checkbox.datapolicy.link.text')}
                      </a>&nbsp;
                      {t('userIdentity.inputs.checkbox.datapolicy.text-after')}
                    </>
                  }
                  {...field}
                />
              }
            />
          </Box>
          <Container maxWidth="md" sx={{ display: "flex", justifyContent: 'space-evenly', width: '90%', mt: '2%', flexWrap: 'wrap-reverse' }}>
            <StyledButton
              variant="outlined"
              color="secondary"
              size={width <= 900 ? "medium" : "large"}
              sx={{ m: '10px', textTransform: 'uppercase' }}
              onClick={() => setStep(1)}
            >
                {t('button.back')}
            </StyledButton>
            <StyledButton
              variant="contained"
              disabled={!isValid}
              type="submit"
              size={width <= 900 ? "medium" : "large"}
              sx={{ m: '10px', textTransform: 'uppercase' }}
            >
                {t('button.next-step')}
            </StyledButton>
          </Container>
        </Box>
      </Container>
    </form>
  );
};

export default UserIdentity;
