import { Typography, Box, IconButton } from "@mui/material"
import { useCallback, useState } from "react"
import { checkTokens } from "../../utils/apiCalls"
import { Redirect } from "react-router-dom"
import { StyledButton } from "../../shared/basic/StyledButton"
import CommonInput from "../../shared/basic/CommonInput"
import Eye from "../../icons/Eye"
import EyeCrossed from "../../icons/EyeCrossed"
import { useWindowResize } from "../../utils/useWindowResize"
import { useTranslation } from "react-i18next"
import { setStoredTokens } from "../../utils/setStoredTokens"

export const LoginPage = () => {
    const [key, setKey] = useState()
    const [secret, setSecret] = useState()
    const [authorised, setAuthorised] = useState(false)
    const [visible, setVisible] = useState(false)
    const [err, setErr] = useState('')
    const [width] = useWindowResize()
    const { t } = useTranslation()

    const onLogin = useCallback(async () => {
        try {
            await checkTokens(key, secret)
            setStoredTokens(key, secret)
            setAuthorised(true)
        } catch (e) {
            if (typeof e.response.data === 'object') {
                setErr(t(`loginPage.errors.default-error`))
            } else {
                setErr(t(`loginPage.errors.${e.response.data.toLowerCase().split(' ').join('-')}`))
            }
            setStoredTokens()
            setAuthorised(false)
        }
    }, [key, secret])

    return (
        <>
            {!authorised ?
                <Box
                    sx={width <= 900 ?
                        { textAlign: 'center' }
                        :
                        { width: '40%', float: 'right', m: '1%', ml: 'auto', textAlign: 'left' }
                    }
                >
                    <Typography variant="h2" sx={{ mb: '64px' }}>{t('loginPage.title')}</Typography>
                    <Box>
                        <Box sx={{ mb: '34px', textAlign: 'left' }}>
                            <Typography variant="bodyL" align="left">{t('loginPage.inputs.username')}</Typography>
                            <CommonInput
                                placeholder={t('loginPage.inputs.username')}
                                sx={{ width: '100%', '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } }}
                                onChange={(e) => setKey(e.target.value)} />
                        </Box>
                        <Box sx={{ textAlign: 'left' }}>
                            <Typography variant="bodyL" align="left">{t('loginPage.inputs.password')}</Typography>
                            <CommonInput
                                placeholder={t('loginPage.inputs.password')}
                                sx={{ width: '100%' }}
                                type={visible ? 'text' : 'password'}
                                staticicon={
                                    <IconButton
                                        onClick={() => setVisible(!visible)}
                                    >{visible ? <EyeCrossed /> : <Eye />}</IconButton>
                                }
                                onChange={(e) => setSecret(e.target.value)}
                            />
                        </Box>
                        <Typography variant="bodyL" sx={{ color: '#D42D05', textAlign: 'left' }}>{err || ''}</Typography>
                    </Box>
                    <StyledButton
                        size={width <= 900 ? "medium" : "large"}
                        variant="contained"
                        onClick={onLogin}
                        disabled={!key || !secret}
                        sx={{ mt: '50px', textTransform: 'uppercase' }}
                    >{t('button.log-in')}</StyledButton>
                </Box> : <Redirect to="/follow-report" />
            }
        </>
    )
}