import { getStoredKey } from "./getStoredKey"
import { getStoredSecret } from "./getStoredSecret"

export const downloadFile = (func, attachmentId) => {
    try {
        const a = document.createElement("a")
        a.href = func(
            localStorage.getItem('access_key') || getStoredKey(),
            localStorage.getItem('access_secret') || getStoredSecret(),
            attachmentId || '')
        a.click()
        a.remove()
    } catch (e) { console.warn(e) }
}