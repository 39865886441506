import { Typography, Box } from "@mui/material"
import { useEffect, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { v4 as uuid } from 'uuid';
import { FileChip } from "./FileChip.jsx";
import theme from "../../styles/theme.js";
import Download from "../../icons/Download.jsx"
import { useTranslation } from "react-i18next";

const validMimeTypes = [
  'video/quicktime',
  'application/pdf',
  'image/png', 'image/jpeg',
  'audio/mpeg', 'video/mp4',
  'video/x-msvideo',
  'video/x-m4v',
  'video/x-flv',
  'audio/x-m4a'
];

export const FileDropzone = ({ onDrop, sizeFile }) => {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation()


  function aceeptFileValidaror(file) {
    const isValid = validMimeTypes.includes(file.type);
    if (!isValid) { return { code: "wrong file format", message: t('dropzone.error') }; }
    return null
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: sizeFile,
    validator: aceeptFileValidaror,
    onDropAccepted: (fileAccepted) => {
      setFiles((fileList) => {
        fileAccepted[0]['id'] = uuid();
        return [...fileList, fileAccepted]
      })
    },
    onDropRejected: (fileRejected) => {
      setFiles((fileList) => {
        fileRejected[0]['id'] = uuid();
        return [...fileList, fileRejected]
      })
    },
  });

  const handleFileRemove = useCallback((index) => {
    setFiles(files.filter((item) => item[0].id !== index))
  }, [files]);

  useEffect(() => {
    const newFiles = files.reduce((acum, item) => {
      if (!item[0].errors) {
        acum.push(item[0]);
        return acum;
      };
      return acum;
    }, []);

    onDrop(newFiles);
  }, [files]);

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          border: `
            1px ${isDragActive ? 'dashed' : 'solid'}
            ${isDragActive ? '#ffffff' : theme.palette.additionalColors.stroke}
          `,
          backgroundColor: `${isDragActive ? theme.palette.mainColors.green : theme.palette.additionalColors.lightGreen}`,
          borderRadius: '10px',
          transition: '0.1s',
          p: '58px 0'
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Download color={(isDragActive && '#FFFFFF') || '#AD8407'} sx={{ display: 'block', m: 'auto', color: 'transparent', fontSize: '58px', mb: '20px' }} />
        <Typography variant="bodyL_dimmed" color={isDragActive && '#FFFFFF'}>
          {t('dropzone.inner-text.text')} <Typography variant="bodyL_accentMain" color={(isDragActive && '#FFFFFF') || '#AD8407'}>{t('dropzone.inner-text.text-accent')}</Typography>
        </Typography>
      </Box>
      <Typography variant="bodyS_dimmed" sx={{ textAlign: 'left' }}>
        {t('dropzone.subtitles.supported-formats.label')}&nbsp; <Typography variant="bodyS_accent">{t('dropzone.subtitles.supported-formats.formats')}</Typography>
      </Typography>
      <Typography variant="bodyS_dimmed" sx={{ display: 'inline-block' }}>&nbsp;{t('dropzone.subtitles.max-size.label')} <Typography variant="bodyS_accent">{t('dropzone.subtitles.max-size.size')}</Typography></Typography>
      {files.map(((item) =>
        <Box key={item[0].id}>
          {item[0].errors ?
            <FileChip
              fileName={item[0].file.name}
              fileId={item[0].id}
              type={item[0].file.name.split('.').pop().toUpperCase()}
              errMessage={item[0].errors[0].message}
              removeFunc={handleFileRemove}
            />
            :
            <FileChip
              fileName={item[0].name}
              fileId={item[0].id}
              type={item[0].name.split('.').pop().toUpperCase()}
              removeFunc={handleFileRemove}
              size={item[0].size}
            />
          }
        </Box>
      ))}
    </>
  )
}