import { Box, Chip, Divider, Typography } from "@mui/material"
import { getAttachmentLink } from "../../../utils/apiCalls"
import { downloadFile } from "../../../utils/downloadFIle"
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { StatusChip } from "../../../shared/basic/StatusChip";
import { useWindowResize } from "../../../utils/useWindowResize.js"
import theme from "../../../styles/theme";
import { useTranslation } from "react-i18next";

export const ReportBox = ({ subject, description, category, attachments, status }) => {
    const [width] = useWindowResize()
    const { t } = useTranslation()
    const reportStatus = {
        1: <StatusChip sx={{ display: 'inline-block' }}>{t('followReportPage.report-box.status.new')}</StatusChip>,
        2: <StatusChip sx={{ display: 'inline-block' }} accent="green">{t('followReportPage.report-box.status.received')}</StatusChip>,
        3: <StatusChip sx={{ display: 'inline-block' }} accent="red">{t('followReportPage.report-box.status.under-investigation')}</StatusChip>,
        4: <StatusChip sx={{ display: 'inline-block' }} accent="green">{t('followReportPage.report-box.status.waiting-for-blower')}</StatusChip>,
        5: <StatusChip sx={{ display: 'inline-block' }} accent="blue">{t('followReportPage.report-box.status.send-to-company')}</StatusChip>,
        6: <StatusChip sx={{ display: 'inline-block' }} accent="blue">{t('followReportPage.report-box.status.closed')}</StatusChip>,
    }

    return (
        <Box sx={{ m: '1%', p: '24px 0', borderRadius: '15px', boxShadow: '4px 8px 40px 0px #243F7314' }}>
            <Box sx={{ m: ' 0 24px 24px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
                <Typography variant="h4" sx={{ mr: '10px', display: 'block' }}>{t('followReportPage.report-box.title')}</Typography>
                <Box sx={width <= 575 ? { flex: '0 0 100%', order: 1, textAlign: 'center' } : ''}>
                    {width <= 575 ?
                        <Chip
                            variant="outlined"
                            sx={{
                                p: '4px 16px',
                                mr: '10px',
                                borderColor: theme.palette.additionalColors.stroke,
                                backgroundColor: theme.palette.additionalColors.lightGrey,
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                textAlign: 'center'
                            }}
                            label={
                                <Typography variant="bodyL" sx={{ whiteSpace: 'normal', color: theme.palette.additionalColors.grey }}>
                                    {t(`complainReport.inputs.category.names.${category?.split(' ').join('-').toLowerCase()}`)}
                                </Typography>
                            }
                        /> : <StatusChip>{t(`complainReport.inputs.category.names.${category?.split(' ').join('-').toLowerCase()}`)}</StatusChip>
                    }
                </Box>
                <Box sx={{ m: '10px', flexGrow: 1, textAlign: 'end' }}>{reportStatus[status]}</Box>
            </Box>
            <Divider sx={{ width: '100%' }} />
            <Box sx={{ textAlign: 'left', m: '1% 24px', wordWrap: 'break-word' }}>
                <Typography variant="bodyL_dimmed">{t('followReportPage.report-box.labels.subject')}</Typography>
                <br />
                <Typography variant="title1">{subject}</Typography>
                <br />
                <Typography variant="bodyL_dimmed">{t('followReportPage.report-box.labels.description')}</Typography>
                <br />
                <Typography variant="inputText">{description}</Typography>
                <Box sx={{ mt: '15px' }}>
                    {attachments?.map((item) =>
                        <Chip
                            icon={<FileOpenIcon />}
                            label={<Typography variant="bodyL">{item.attachment_name}</Typography>}
                            sx={{ mr: '10px', overflow: 'hidden', maxWidth: '100%' }}
                            clickable
                            size="small"
                            onClick={() => downloadFile(getAttachmentLink, item.attachment_id)}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}