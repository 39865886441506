import './App.css';
import { Header } from './shared/basic/Header';
import { Footer } from './shared/basic/Footer';
import { Main } from './shared/basic/Main';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import { useState, useEffect, Suspense } from 'react';
import loginBackground from './styles/img/loginBackground.svg';
import mainBackgroundRight from './styles/img/mainBackgroundRight.svg';
import mainBackgroundLeft from './styles/img/mainBackgroundLeft.svg';
import translationsEn from './utils/languages/translationsEn.json';
import translationsDe from './utils/languages/translationsDe.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Typography } from '@mui/material';
import { ReportProvider } from './shared/contexts/reportContext';
import { StepsProvider } from './shared/contexts/stepsContext';
import { CompanyProvider } from './shared/contexts/companyContext';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationsEn },
      de: { translation: translationsDe },
    },
    lng: 'de',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  })

export const history = createBrowserHistory()

function App() {
  const [path, setPath] = useState(window.location.href)

  useEffect(() => { return history.listen((location) => { setPath(location.pathname) }) })
  useEffect(() => setPath(history.location.pathname), [])

  const backgrounds = {
    '/login': `no-repeat left url(${loginBackground})`,
    '/': `no-repeat right url(${mainBackgroundRight}), no-repeat left url(${mainBackgroundLeft})`
  };

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Typography variant="bodyL_dimmed">Loading...</Typography>}>
          <div
            className="App"
            style={{
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              background: backgrounds[path]
            }}
          >
            <StepsProvider>
                <ReportProvider>
                    <CompanyProvider>
                        <Header />
                        <Main />
                    </CompanyProvider>
                </ReportProvider>
            </StepsProvider>
            <Footer />
          </div>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
}

export default App;
