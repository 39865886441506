import { SvgIcon } from "@mui/material";

const FileAcceptedIcon = ({format, ...props}) => (
  <SvgIcon {...props} width="24" height="31" viewBox="0 0 24 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 30.4998H3C1.34315 30.4998 0 29.1567 0 27.4998V3.49984C0 1.84299 1.34315 0.499842 3 0.499842H13.5C13.5134 0.498099 13.5271 0.498099 13.5405 0.499842H13.5495C13.5637 0.504279 13.5782 0.507294 13.593 0.508842C13.7253 0.517322 13.8559 0.54304 13.9815 0.585342H14.004H14.0265H14.0445C14.0722 0.604731 14.0983 0.6263 14.1225 0.649842C14.2859 0.722478 14.4348 0.824119 14.562 0.949842L23.562 9.94984C23.6877 10.077 23.7894 10.2259 23.862 10.3893C23.8755 10.4223 23.886 10.4538 23.8965 10.4883L23.9115 10.5303C23.9534 10.6554 23.9781 10.7856 23.985 10.9173C23.9863 10.9323 23.9898 10.9469 23.9955 10.9608V10.9698C23.998 10.9797 23.9995 10.9897 24 10.9998V27.4998C24 28.2955 23.6839 29.0586 23.1213 29.6212C22.5587 30.1838 21.7956 30.4998 21 30.4998ZM13.5 3.49984V10.9998H21L13.5 3.49984Z" fill="#7AA6A3"/>
    <text x="50%" y="65%" fill="#FFF" fontSize="6" alignmentBaseline="middle" textAnchor="middle">{format}</text>
  </SvgIcon>
)



export default FileAcceptedIcon;
