import { SvgIcon } from "@mui/material";

const EyeCrossed = ({ format, ...props }) => (
    <SvgIcon {...props} width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26 23.9995C25.8687 23.9997 25.7386 23.9739 25.6173 23.9236C25.4959 23.8733 25.3858 23.7994 25.2931 23.7064L3.29312 1.70635C3.1135 1.51729 3.01484 1.26554 3.01818 1.00478C3.02152 0.744015 3.12659 0.494873 3.31099 0.310471C3.49539 0.12607 3.74454 0.0209987 4.0053 0.0176602C4.26606 0.0143216 4.51781 0.11298 4.70687 0.2926L26.7069 22.2926C26.8466 22.4325 26.9418 22.6106 26.9803 22.8045C27.0189 22.9984 26.9991 23.1994 26.9234 23.3821C26.8478 23.5648 26.7197 23.7209 26.5553 23.8308C26.391 23.9407 26.1977 23.9994 26 23.9995Z" fill="#807E78" />
        <path d="M14.5 15.7401L11.2631 12.5032C11.2446 12.4848 11.2207 12.4727 11.1949 12.4686C11.1692 12.4644 11.1427 12.4685 11.1193 12.4801C11.096 12.4918 11.0768 12.5105 11.0646 12.5335C11.0524 12.5566 11.0477 12.583 11.0512 12.6088C11.1819 13.4486 11.5762 14.2251 12.1772 14.826C12.7781 15.427 13.5546 15.8213 14.3944 15.952C14.4203 15.9555 14.4466 15.9508 14.4697 15.9386C14.4928 15.9264 14.5115 15.9073 14.5231 15.8839C14.5348 15.8605 14.5388 15.8341 14.5347 15.8083C14.5305 15.7825 14.5184 15.7586 14.5 15.7401Z" fill="#807E78" />
        <path d="M15.5 8.25885L18.7419 11.4995C18.7604 11.5181 18.7843 11.5305 18.8102 11.5348C18.8361 11.5391 18.8627 11.5351 18.8863 11.5234C18.9098 11.5117 18.929 11.4929 18.9412 11.4696C18.9535 11.4464 18.9581 11.4199 18.9544 11.3938C18.824 10.5529 18.4294 9.77534 17.8277 9.17363C17.226 8.57191 16.4484 8.17732 15.6075 8.04697C15.5815 8.04296 15.5548 8.04727 15.5314 8.0593C15.5079 8.07133 15.4889 8.09046 15.477 8.11395C15.465 8.13745 15.4609 8.16411 15.465 8.19014C15.4691 8.21616 15.4814 8.24021 15.5 8.25885Z" fill="#807E78" />
        <path d="M29.6875 13.0845C29.8944 12.7597 30.0038 12.3824 30.0027 11.9973C30.0016 11.6122 29.89 11.2355 29.6812 10.912C28.0275 8.35448 25.8819 6.18197 23.4769 4.62885C20.8125 2.90885 17.875 1.99947 14.98 1.99947C13.4538 2.00157 11.938 2.25041 10.4912 2.73635C10.4508 2.74982 10.4144 2.77343 10.3856 2.80493C10.3568 2.83643 10.3366 2.87477 10.3268 2.91631C10.317 2.95785 10.318 3.0012 10.3298 3.04223C10.3415 3.08327 10.3635 3.12061 10.3937 3.15072L13.3462 6.10322C13.3769 6.13395 13.4151 6.15616 13.4569 6.16767C13.4988 6.17918 13.5429 6.17958 13.585 6.16885C14.5858 5.92496 15.6325 5.94283 16.6244 6.22074C17.6162 6.49865 18.5199 7.02724 19.2482 7.75561C19.9766 8.48398 20.5052 9.3876 20.7831 10.3795C21.061 11.3713 21.0789 12.4181 20.835 13.4188C20.8244 13.4608 20.8248 13.5048 20.8363 13.5466C20.8478 13.5883 20.87 13.6264 20.9006 13.657L25.1475 17.907C25.1917 17.9512 25.2509 17.9773 25.3133 17.9801C25.3758 17.9829 25.4371 17.9621 25.485 17.922C27.1197 16.5286 28.5363 14.898 29.6875 13.0845Z" fill="#807E78" />
        <path d="M15 17.9995C14.0917 17.9995 13.1953 17.7934 12.3783 17.3965C11.5613 16.9997 10.845 16.4226 10.2835 15.7086C9.72201 14.9947 9.32993 14.1626 9.13682 13.2751C8.94372 12.3876 8.95464 11.4678 9.16875 10.5851C9.17937 10.5431 9.1789 10.4991 9.1674 10.4574C9.15589 10.4156 9.13374 10.3776 9.10312 10.347L4.92562 6.1676C4.88133 6.12325 4.82199 6.09716 4.75936 6.0945C4.69674 6.09184 4.6354 6.1128 4.5875 6.15322C3.06312 7.45385 1.65 9.03635 0.358122 10.8857C0.131987 11.2103 0.00744583 11.5948 0.00032352 11.9903C-0.00679879 12.3858 0.103819 12.7745 0.318122 13.107C1.96875 15.6901 4.0925 17.8657 6.46062 19.3976C9.12875 21.1245 11.995 21.9995 14.98 21.9995C16.5216 21.9953 18.0532 21.7518 19.52 21.2776C19.5609 21.2646 19.5977 21.2412 19.6269 21.2098C19.6561 21.1784 19.6768 21.14 19.6869 21.0984C19.697 21.0567 19.6962 21.0131 19.6846 20.9718C19.673 20.9305 19.651 20.8929 19.6206 20.8626L16.6537 17.8964C16.6231 17.8657 16.5851 17.8436 16.5434 17.8321C16.5016 17.8206 16.4576 17.8201 16.4156 17.8307C15.9521 17.943 15.4769 17.9997 15 17.9995Z" fill="#807E78" />

    </SvgIcon>
)

export default EyeCrossed;