import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mainColors: {
            white: '#FFFFFF',
            yellow: '#B58E00',
            green: '#7AA6A3'
        },
        additionalColors: {
            lightGrey: '#d6d6d6',
            lightGreen: '#CAFFE5',
            stroke: '#E4E7ED',
            olive: '#677068',
            black: '#2C291F',
            grey: '#65635E',
            red: '#DE6565',
            lightRed: '#FFEFEF',
            blue: '#6480CE',
            lightBlue: '#EEF3FE',
        },
        primary: {
            main: '#B58E00',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#CAFFE5',
            contrastText: '#65635E'
        },
        action: {
            hoverPrimary: '#D9AF07',
            hoverSecondary: '#D8F1FB',
            focusPrimary: '#E3C249',
            active: '#E3C249',
            disabledBackground: '#ABBAAC'
        },
        error: {
            main: '#AF4645',
        },
    },
    typography: {
        h1: {
            fontFamily: 'Oswald',
            fontWeight: 600,
            fontSize: '64px',
            lineHeight: '76.8px',
            letterSpacing: '0.02em',
            color: '#2C291F'
        },
        h1_accent: {
            fontFamily: 'Oswald',
            fontWeight: 600,
            fontSize: '64px',
            lineHeight: '76.8px',
            letterSpacing: '0.02em',
            color: '#7AA6A3'
        },
        h2: {
            fontFamily: 'Oswald',
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: '38.4px',
            letterSpacing: '0.01em',
            color: '#2C291F'
        },
        h2_accent: {
            fontFamily: 'Oswald',
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: '38.4px',
            letterSpacing: '0.01em',
            color: '#7AA6A3'
        },
        h3: {
            fontFamily: 'Oswald',
            fontWeight: 600,
            fontSize: '30px',
            lineHeight: '36px',
            color: '#2C291F'
        },
        h4: {
            fontFamily: 'Oswald',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '28.8px',
            color: '#2C291F'
        },
        title1: {
            fontFamily: 'Readex Pro',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#2C291F'
        },
        title1_dimmed: {
            fontFamily: 'Readex Pro',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#65635E'
        },
        title2: {
            fontFamily: 'Readex Pro',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '32px',
            color: '#2C291F'
        },
        title2_accentSecondary: {
            fontFamily: 'Readex Pro',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '32px',
            color: '#5B7D7B'
        },
        title2_accentMain: {
            fontFamily: 'Readex Pro',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '32px',
            color: '#B58E00'
        },
        title3_accentMain: {
            fontFamily: 'Readex Pro',
            fontWeight: 700,
            fontSize: '35px',
            lineHeight: '32px',
            color: '#B88E12'
        },
        subtextH1: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '22px',
            lineHeight: '30.8px',
            color: '#65635E'
        },
        subtextH2: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
            color: '#65635E'
        },
        subtextH2_accent: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '32px',
            color: '#7AA6A3'
        },
        bodyL: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2C291F'
        },
        bodyL_accentMain: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#F2BE22'
        },
        bodyL_dimmed: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#65635E'
        },
        bodyS: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '17.4px',
            color: '#2C291F'
        },
        bodyS_accent: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '17.4px',
            color: '#5B7D7B'
        },
        bodyS_dimmed: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '17.4px',
            color: '#65635E'
        },

        inputText: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '29px',
        },
        inputText_dimmed: {
            fontFamily: 'Readex Pro',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '29px',
            color: '#65635E'
        },
        button: {
            fontFamily: 'Readex Pro',
            fontWeight: 600,
            fontSize: '19px',
            lineHeight: '23.75px',
            textTransform: 'none'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            msm: 300,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

export default theme;
