import { Chip, Typography } from "@mui/material"
import theme from "../../styles/theme";

export const StatusChip = ({ accent = 'grey', children }) => {
    const colors = {
        red: [theme.palette.additionalColors.red, theme.palette.additionalColors.lightRed],
        green: [theme.palette.mainColors.green, theme.palette.additionalColors.lightGreen],
        blue: [theme.palette.additionalColors.blue, theme.palette.additionalColors.lightBlue],
        grey: [theme.palette.additionalColors.grey, theme.palette.additionalColors.lightGrey]
    }
    return (
        <Chip
            variant="outlined"
            sx={{
                p: '4px 16px',
                borderColor: theme.palette.additionalColors.stroke,
                backgroundColor: colors[accent][1],
                borderRadius: '10px',
                height: '100%',
                textAlign: 'center'
            }}
            label={<Typography variant="bodyL" sx={{ whiteSpace: 'normal', color: colors[accent][0] }}>{children}</Typography>}
        />
    )
}