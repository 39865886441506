import React, { useContext, useState, useEffect } from 'react';
import { getCategoriesList } from "../../utils/apiCalls.js";

const ReportContext = React.createContext({});

export const ReportProvider = ({ children }) => {
    const [report, setReport] = useState({});
    const [categories, setCategories] = useState([]);
    const [isCategoryError, setIsCategoryError] = useState(false);

    const getCategories = async () => {
        try {
            const { data: { data: { categories }} } = await getCategoriesList();

            setCategories(categories);
        } catch (e) {
            setIsCategoryError(true);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
      <ReportContext.Provider
        value={{
            report,
            categories,
            isCategoryError,
            setReport,
            setCategories,
            getCategories,
        }}
      >
        {children}
      </ReportContext.Provider>
    );
  };

export const useReportContext = () => useContext(ReportContext);

export default ReportContext;
