import { styled } from "@material-ui/styles"
import { Button } from "@mui/material"
import theme from "../../styles/theme";

const CustomButton = styled(Button)(({ theme }) => ({
    '&.MuiButton-sizeSmall': { minWidth: '102px', height: '35px', fontSize: '16px', lineHeight: '19.2px' },
    '&.MuiButton-sizeMedium': { minWidth: '218px', height: '57px' },
    '&.MuiButton-sizeLarge': { minWidth: '273px', height: '72px' },
    '&.MuiButton-containedPrimary': {
        boxShadow: 'none',
        '&:hover': { backgroundColor: theme.palette.action.hoverPrimary },
        '&:active': { backgroundColor: theme.palette.action.active },
        '&:focus': { backgroundColor: theme.palette.action.focusPrimary },
        '&:disabled': { color: theme.palette.mainColors.white }
    },
    '&.MuiButton-outlinedSecondary': {
        color: theme.palette.additionalColors.grey,
        borderColor: theme.palette.additionalColors.stroke,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        '&:hover': { backgroundColor: theme.palette.action.hoverSecondary },
        '&:active': {
            backgroundColor: theme.palette.additionalColors.lightGreen,
            borderColor: theme.palette.mainColors.green
        },
        '&:focus': {
            backgroundColor: theme.palette.mainColors.white,
            borderColor: theme.palette.mainColors.green
        },
        '&:disabled': {
            backgroundColor: theme.palette.additionalColors.lightGrey,
            borderColor: theme.palette.additionalColors.lightGrey
        }
    }
}));


export const StyledButton = (props) => {
    return (
        <CustomButton
            theme={theme}
            disableRipple
            onClick={e => e.target.blur()}
            {...props}
        >{props.children}</CustomButton>
    )
}
