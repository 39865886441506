import { IconButton, Stack, Typography } from "@mui/material";
import BucketDelete from "../../icons/BucketDelete";
import FileAcceptedIcon from "../../icons/FileAccepted";
import theme from "../../styles/theme";
import { useWindowResize } from "../../utils/useWindowResize";

export const FileChip = ({ type, fileName, fileId, size, errMessage, style, removeFunc }) => {
    const [width] = useWindowResize()

    return (
        <Stack
            direction="row"
            spacing={1}
            {...style}
            sx={{
                p: '15px',
                m: '8px 4px',
                alignItems: 'center',
                border: `1px solid`,
                borderColor: `${errMessage ? theme.palette.additionalColors.red : theme.palette.additionalColors.stroke}`,
                borderRadius: '10px',
                backgroundColor: `${errMessage ? theme.palette.additionalColors.lightGrey : 'transparent'}`,
                height: '69px',
                textOverflow: 'ellipsis',
                whiteSpace: 'wrap'
            }}>
            <FileAcceptedIcon format={type} sx={{ fontSize: width <= 300 ? '20px' : '30px' }} />
            <Stack sx={{ width: '100%', textAlign: 'left' }}>
                <Typography variant={width <= 300 ? "bodyS" : "bodyL"}>{fileName}</Typography>
                {errMessage ?
                    <Typography variant="bodyS_dimmed" sx={{ color: theme.palette.additionalColors.red }}>{errMessage}</Typography>
                    :
                    <Typography variant="bodyS_dimmed">{Math.round(size * 0.001)} KB</Typography>
                }
            </Stack>
            <IconButton onClick={() => removeFunc(fileId)}><BucketDelete sx={{ fontSize: '22px' }} /></IconButton>
        </Stack >
    )
}