import { SvgIcon } from "@mui/material";

const InputError = ({...props}) => (
  <SvgIcon  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 13C0 5.82272 5.82272 0 13 0C20.1773 0 26 5.82272 26 13C26 20.1773 20.1773 26 13 26C5.82272 26 0 20.1773 0 13ZM13 2C6.92728 2 2 6.92728 2 13C2 19.0727 6.92728 24 13 24C19.0727 24 24 19.0727 24 13C24 6.92728 19.0727 2 13 2Z" fill="#AF4645"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9868 6.00317C13.1714 6.00141 13.3545 6.0373 13.5248 6.10864C13.6952 6.18003 13.8492 6.28541 13.9775 6.41834C14.1058 6.55128 14.2056 6.70897 14.2709 6.88179C14.3361 7.0546 14.3655 7.23891 14.3571 7.42347L13.9989 15.0469C13.9738 15.5804 13.5341 16 13 16C12.466 16 12.0262 15.5804 12.0011 15.047L11.6423 7.42347C11.634 7.24111 11.6626 7.05895 11.7262 6.88788C11.7899 6.71681 11.8874 6.56032 12.0129 6.42775C12.1384 6.29519 12.2893 6.18927 12.4566 6.11632C12.6239 6.04341 12.8041 6.00493 12.9865 6.00317" fill="#AF4645"/>
    <path d="M13 19.9944C12.7528 19.9944 12.5111 19.9211 12.3055 19.7837C12.1 19.6464 11.9398 19.4511 11.8452 19.2227C11.7505 18.9943 11.7258 18.743 11.774 18.5005C11.8222 18.258 11.9413 18.0353 12.1161 17.8605C12.2909 17.6857 12.5137 17.5666 12.7561 17.5184C12.9986 17.4702 13.2499 17.4949 13.4784 17.5895C13.7068 17.6841 13.902 17.8444 14.0393 18.0499C14.1767 18.2555 14.25 18.4971 14.25 18.7444C14.25 19.0759 14.1183 19.3938 13.8839 19.6283C13.6495 19.8627 13.3315 19.9944 13 19.9944Z" fill="#AF4645"/>
  </SvgIcon>
)

export default InputError;

