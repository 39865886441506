import ReplyIcon from '../../../icons/ReplyIcon';
import { Chip, Divider, Typography, Box } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { getAttachmentLink } from '../../../utils/apiCalls';
import { downloadFile } from '../../../utils/downloadFIle';
import { useTranslation } from "react-i18next";

export const Message = ({ from, children, date, isReporter, attachments }) => {
    const { t } = useTranslation()
    return (
        <>
            <Divider />
            <Box sx={{ mb: '10px' }}>
                {attachments.map((item) => {
                    return (
                        <Chip
                            icon={<FileOpenIcon />}
                            label={<Typography variant="bodyL" sx={{ overflow: 'hidden' }}>{item.attachment_name}</Typography>}
                            sx={{ m: '5px', borderRadius: '10px', overflow: 'hidden', maxWidth: '100%' }}
                            clickable
                            size="small"
                            onClick={() => downloadFile(getAttachmentLink, item.attachment_id)}
                        />
                    )
                })}
            </Box>
            <Box variant="div" sx={{ m: '1%', wordWrap: 'break-word' }}>
                <Box variant="div" sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
                    {from ?
                        <Typography
                            variant="title2"
                            sx={{ textAlign: 'left', display: 'block' }}
                        >{isReporter ? <ReplyIcon style={{ fontSize: '18px' }} /> : ''} {from}</Typography> :
                        <Typography variant="title2" sx={{ textAlign: 'left', display: 'block' }}>
                            <ReplyIcon style={{ fontSize: '18px' }} /> {t('followReportPage.message-box.message.you')}
                        </Typography>
                    }
                    <Typography
                        variant="bodyS_dimmed"
                    >{date}</Typography>
                </Box>
                <Typography variant="bodyL" sx={{ textAlign: 'justify', width: '100%' }}>{children}</Typography>
            </Box>
        </>
    )
}