import { forwardRef} from 'react';
import { useTranslation } from "react-i18next";
import { CustomSelect, StyledOption } from './CustomizedSelectBase';


function RenderValue(option) {
  const { t } = useTranslation()
  if (!option) {
    return (<div>{t('complainReport.inputs.category.placeholder')}</div>);
  }

  return (<div className='categoty'>{option.label}</div>);
}

const CategorySelect = forwardRef((props, ref) => {
  const { field, categories, ...others } = props;
  const { t } = useTranslation()
  return (
    <CustomSelect {...field} {...others} ref={ref} renderValue={RenderValue}>
      {categories.map((c) => (
        <StyledOption key={c.category_id} value={c.category_id}>
          {t(`complainReport.inputs.category.names.${c.category_name.split(' ').join('-').toLowerCase()}`)}
        </StyledOption>
      ))}
    </CustomSelect>
  );
})

export default CategorySelect;
