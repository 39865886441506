import { Divider, Typography, Box } from "@mui/material"

export const CustomInput = ({ name, sup, sub, children }) => {
    return (
        <Box component="div" sx={{ textAlign: 'start' }}>
            <Divider sx={{ mb: '1rem' }} />
            <Typography variant="title2_accentMain">{sup}</Typography>
            <Typography variant="h3">{name}</Typography>
            <br />
            <Typography variant="subtextH2" >{sub}</Typography>
            <Box component="div" m="auto">{children}</Box>
            <Divider sx={{ mt: '2rem' }} />
        </Box>
    )
}