import { useState, useEffect } from "react"
import { Box, Container, Paper, Typography } from '@mui/material';
import { history } from '../../App';
import { getPdfLink } from "../../utils/apiCalls"
import { downloadFile } from '../../utils/downloadFIle';
import { StyledButton } from '../../shared/basic/StyledButton';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import InputError from "../../icons/InputError";
import { useWindowResize } from "../../utils/useWindowResize";
import { useTranslation } from "react-i18next";

const PageWithTokens = () => {
  const [tokens, setTokens] = useState(localStorage.getItem('access_key') && localStorage.getItem('access_secret'))
  const [err, setErr] = useState('')
  const events = ['mousemove', 'keydown', 'mousewheel', 'scroll', 'click']
  const [width] = useWindowResize()
  const { t } = useTranslation()

  const download = async () => {
    try {
      downloadFile(getPdfLink)
    } catch (e) {
      setErr('Unable to download your report, please try again.')
    }
  }

  const logout = () => {
    localStorage.setItem('access_key', '')
    localStorage.setItem('access_secret', '')
    setTokens(false)
    events.forEach((item) => document.removeEventListener(item, updateTimer))
    clearTimeout(timeout)
  }

  let timeout = null

  const updateTimer = () => {
    clearTimeout(timeout)
    timeout = setTimeout(logout, 60000)
  }

  useEffect(() => {
    (async () => {
      events.forEach((item) => document.addEventListener(item, updateTimer))
      timeout = setTimeout(logout, 60000)
    })()
  }, [])

  return (
    <>
      {tokens ?
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          <Box m="auto" sx={{ textAlign: 'center', mb: '2%' }}>
            <Typography variant='title3_accentMain'>{t('pageWithTokens.thanks')}</Typography>
            <Typography variant={width <= 900 ? 'h2' : 'h1'}>
              {t('pageWithTokens.title.text')} <Typography variant={width <= 900 ? 'h2_accent' : 'h1_accent'} sx={{ display: 'inline-block' }}>{t('pageWithTokens.title.text-accent')}</Typography>
            </Typography>
            <Typography variant='bodyL' align='center' component="div" sx={{ mt: '3%', mb: '3%' }}>
              <Typography variant='bodyL_dimmed'>{t('pageWithTokens.additional-text.text')}</Typography>
              <a href={t('pageWithTokens.additional-text.link')} style={{ color: '#7AA6A3' }} target="_blank">
                <Typography variant='bodyL_dimmed'>{t('pageWithTokens.additional-text.link')}</Typography>
              </a>
            </Typography>
            <Typography variant={width <= 900 ? 'subtextH2' : 'subtextH1'} align='center' sx={{ m: '3% 0' }}>{t('pageWithTokens.subtitle')}</Typography>
          </Box>
          <Paper
            elevation={3}
            sx={{ width: '300px', height: '150px', m: '0 auto', borderRadius: '10px', mt: '32px', mb: '32px' }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', flexWrap: 'wrap' }}
          >
            <Box sx={{ mb: '16px' }}><Link to='/login' onClick={() => localStorage.clear()}>{t('pageWithTokens.additional-text.login')}</Link></Box>
            <Box sx={{ mb: '16px' }}>
              <Typography variant="inputText_dimmed">{t('pageWithTokens.token-box.username')}:</Typography>&nbsp;
              <Typography variant="title1">{localStorage.getItem('access_key')}</Typography>
            </Box>
            <Box>
              <Typography variant="inputText_dimmed">{t('pageWithTokens.token-box.password')}:</Typography>&nbsp;
              <Typography variant="title1">{localStorage.getItem('access_secret')}</Typography>
            </Box>
          </Paper>
          {err && <Typography variant="bodyL" color="red">{err} <InputError sx={{ fontSize: '16px' }} /></Typography>}
          <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'space-evenly', mt: '9%', flexWrap: 'wrap-reverse' }}>
            <StyledButton
              sx={{ m: '10px', textTransform: 'uppercase' }}
              size={width <= 900 ? "medium" : "large"}
              variant="outlined"
              color="secondary"
              onClick={() => { localStorage.clear(); history.push('/') }}
            >{t('button.to-the-homepage')}</StyledButton>
            <StyledButton
              sx={{ m: '10px', textTransform: 'uppercase' }}
              size={width <= 900 ? "medium" : "large"}
              variant="contained"
              onClick={() => download()}
            >{t('button.download-as-pdf')}</StyledButton>
          </Container>
        </Container> : <Redirect to="/" />
      }
    </>
  );
}

export default PageWithTokens;
