import { Container, Box, Typography } from "@mui/material";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createReport, addAttachment } from '../../utils/apiCalls'
import { useWindowResize } from "../../utils/useWindowResize";
import { StyledButton } from "../basic/StyledButton";
import { StyledCheckbox } from "../basic/StyledCheckbox";

const Confirmation = ({ report, attachmentFile, step }) => {
  const [checked, setChecked] = useState(false);
  const [err, setErr] = useState('')
  const { t } = useTranslation()
  const [width] = useWindowResize()
  const history = useHistory();

  const handleChange = (event) => { setChecked(event.target.checked) };

  const sendReport = useCallback(() => {
    createReport(report)
      .then((res) => {
        const { access_key, access_secret } = res.data.data;
        localStorage.setItem('access_key', access_key);
        localStorage.setItem('access_secret', access_secret)

        attachmentFile.length !== 0 ?
          addAttachment(access_key, access_secret, attachmentFile)
            .then((res) => {
              if (res.data.data.status) history.push("/report-credentials");
            }).catch((e) => setErr(t('confirmationPage.error')))
          :
          history.push("/report-credentials");
      }).catch((e) => setErr(t('confirmationPage.error')));
  }, [report, attachmentFile])

  return (
    <Container
      maxWidth="xl"
      sx={{
        p: '64px 0',
        textAlign: 'center',
        borderRadius: '10px',
        boxShadow: '4px 8px 40px rgba(36, 63, 115, 0.08)'
      }}>
      <Container maxWidth="md" sx={{ mb: '7%' }}>
        <Typography variant="h2" sx={{ mb: '24px' }}>{t('confirmationPage.title')}</Typography>
        <Typography variant="subtextH2" align="center">{t('confirmationPage.subtitle')}</Typography>
        <Box m="auto" sx={{ textAlign: 'justify', mt: '32px', mb: '27px' }}>
          <Typography variant="title2"><Typography variant="title2_accentSecondary">{step-1 === 2 ? 'HINWEIS:' : 'ACHTUNG:'} </Typography>{t('confirmationPage.note')}</Typography>
            <Box>
              <Typography variant="title2_accentSecondary">{t('confirmationPage.warning').toUpperCase()}</Typography>
            </Box>
        </Box>
        <StyledCheckbox title={t('confirmationPage.checkbox')} onChange={handleChange} />
      </Container>
      <Typography variant="bodyL" color="red">{err}</Typography>
      <Container maxWidth="md" sx={{ display: "flex", justifyContent: 'space-evenly', width: '90%', mt: '2%', flexWrap: 'wrap-reverse' }}>
        <StyledButton
          sx={{ m: '10px', textTransform: 'uppercase' }}
          variant="outlined"
          color="secondary"
          size={width <= 900 ? "medium" : "large"}
          onClick={() => { history.push('/') }}
        >{t('button.reject')}</StyledButton>
        <StyledButton
          sx={{ m: '10px'}}
          disabled={!checked}
          variant="contained"
          size={width <= 900 ? "medium" : "large"}
          type="submit"
          onClick={sendReport}
        >{t('button.submit-report')}</StyledButton>
      </Container>
    </Container>
  )
}

export default Confirmation;
