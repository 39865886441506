import { useEffect, useState } from "react"

export const useWindowResize = () => {
    const [resolution, setResolution] = useState({ width: document.body.clientWidth, height: document.body.clientHeight })
    const resChange = () => setResolution({ width: document.body.clientWidth, height: document.body.clientHeight })
    useEffect(() => {
        window.addEventListener('resize', resChange)
        return () => { window.removeEventListener('resize', resChange) }
    }, [])

    return [resolution.width, resolution.height]
}