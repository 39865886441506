import { Typography, Box } from "@mui/material"
import { Redirect } from "react-router-dom"
import { MessageBox } from "./MessageBox"
import { ReportBox } from "./ReportBox"
import { useEffect, useState } from "react"
import { getReport } from "../../../utils/apiCalls"
import { useTranslation } from "react-i18next";
import { setStoredTokens } from "../../../utils/setStoredTokens"
import { getStoredKey } from "../../../utils/getStoredKey"
import { getStoredSecret } from "../../../utils/getStoredSecret"

export const FollowReportPage = () => {
    const [report, setReport] = useState()
    const [tokens, setTokens] = useState(getStoredKey() && getStoredSecret())
    const events = ['mousemove', 'keydown', 'mousewheel', 'scroll', 'click']
    const { t } = useTranslation()

    const logout = () => {
        setStoredTokens()
        setTokens(false)
        events.forEach((item) => document.removeEventListener(item, updateTimer))
        clearTimeout(timeout)
    }

    let timeout = null

    const updateTimer = () => {
        clearTimeout(timeout)
        timeout = setTimeout(logout, 60000)
    }

    useEffect(() => {
        (async () => {
            let { data } = await getReport(getStoredKey(), getStoredSecret())
            await setReport(data.data)
            events.forEach((item) => document.addEventListener(item, updateTimer))
            timeout = setTimeout(logout, 60000)
        })()
    }, [])

    return (
        <>
            {tokens ?
                <Box variant="div">
                    <Typography variant="h2" sx={{ ml: '2%' }}>{t('followReportPage.title')}</Typography>
                    <ReportBox
                        subject={report?.subject}
                        description={report?.description}
                        category={report?.category}
                        attachments={report?.attachments}
                        status={report?.report_status}
                    />
                    <MessageBox reporter={report?.reporter} messages={report?.messages} />
                </Box> : <Redirect to='/' />
            }
        </>
    )
}
