import React, { useContext, useState, useEffect } from 'react';
import { getCompany } from "../../utils/apiCalls.js";

const CompanyContext = React.createContext({});

export const CompanyProvider = ({ children }) => {
    const [companyName, setCompanyName] = useState('');
    const [companyContent, setCompanyContent] = useState({});
    const [departments, setDepartments] = useState([]);
    const [companyError, setCompanyError] = useState(false);

    const getCompanyData = async () => {
        try {
            const { data: { data: { name, departments, content }} } = await getCompany();

            setCompanyName(name);
            setCompanyContent(content);
            setDepartments(departments);
        } catch (e) {
            setCompanyError(true);
        }
    };

    useEffect(() => {
        getCompanyData();
    }, []);

    return (
      <CompanyContext.Provider
        value={{
            companyName,
            companyContent,
            departments,
            companyError,
            setCompanyName,
            setCompanyContent,
            setDepartments,
            getCompanyData
        }}
      >
        {children}
      </CompanyContext.Provider>
    );
  };

export const useCompanyContext = () => useContext(CompanyContext);

export default CompanyContext;
