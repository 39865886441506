import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    padding: '0 12px',
    height: '2px',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundSize: '16px 2px, 100% 2px',
      background: `${theme.palette.mainColors.green}`,

    },
  },
  [`&.${stepConnectorClasses.completed}`]: {

    [`& .${stepConnectorClasses.line}`]: {
      background: `${theme.palette.mainColors.green}`,
      padding: '0 12px',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: '3px',
    border: 999,
    background: `${theme.palette.additionalColors.stroke}`,
    backgroundSize: '16px 2px, 100% 2px',
    padding: '0 12px',
    backgroundSize: '16px 2px, 100% 2px',
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mainColors.white,
  fontFamily: `${theme.typography.title1.fontFamily}`,
  fontWeight: '700',
  zIndex: 1,
  color: theme.palette.mainColors.green,
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.additionalColors.stroke}`,
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    background: theme.palette.mainColors.green,
    color: theme.palette.mainColors.white,
    border: `2px solid ${theme.palette.additionalColors.green}`,
  }),
  ...(ownerState.completed && {
    background: theme.palette.mainColors.green,
    color: theme.palette.mainColors.white,
    border: `2px solid ${theme.palette.additionalColors.green}`,
  }),
}));

const MyStack = styled(Stack)(({ step, theme }) => ({
  [`.MuiStep-root:nth-of-type(${step + 1}) .Mui-disabled .MuiStepConnector-line`]: {
    background: 'linear-gradient(to right, transparent 60%, #fff 40%), linear-gradient(to right, #339E75, rgba(65, 96, 144, 0))',
    backgroundSize: '16px 2px, 100% 2px',
  },
  '&': {
    minWidth: '750px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '600px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '375px',
    },
    [theme.breakpoints.down('msm')]: {
      minWidth: '200px',
    },
  },

}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {String(props.icon)}
    </ColorlibStepIconRoot>
  );
}

export default function CustomizedSteppers({ step, maxSteps }) {
  const steps = Array.from({ length: maxSteps }, (value, index) => index + 1);

  return (
    <MyStack step={step} spacing={4}>
      <Stepper alternativeLabel activeStep={step - 1} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}> <StepLabel StepIconComponent={ColorlibStepIcon} /></Step>
        ))}
      </Stepper>
    </MyStack>
  );
}
