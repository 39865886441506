import { Typography, Box } from "@mui/material"
import { useTranslation } from "react-i18next"

export const Footer = () => {
    const { t } = useTranslation()
    return (
        <Box sx={{ textAlign: 'center', mt: 'auto', p: '32px 0 21px' }}>
            <Typography variant="subtextH2">{t('footer.text')}</Typography>
            <Box>
                <a href="https://edward360.de/impressum-com" style={{ textDecorationColor: '#cacaca' }} target="_blank">
                    <Typography variant="bodyL_dimmed">Impressum</Typography>
                </a> &nbsp;
                <a href="https://edward360.de/datenschutz-com" style={{ textDecorationColor: '#cacaca' }} target="_blank">
                    <Typography variant="bodyL_dimmed">Datenschutz</Typography>
                </a>
            </Box>
        </Box>
    )
}