import { useState } from "react";

import Categories from './components/Categories';
import Question from './components/Question';
import Anonymous from './components/Anonymous';
import NonAnonymous from './components/NonAnonymous';

const CreateReport = () => {
    const [step, setStep] = useState(1);

    return (
        <div>
            {(() => {
                switch(step) {
                    default:
                    case 1:
                        return <Categories setStep={setStep} />
                    case 2:
                        return <Question setStep={setStep} />
                    case 3:
                        return <Anonymous setStep={setStep} />
                    case 4:
                        return <NonAnonymous setStep={setStep} />
                }
            })()}
        </div>
    )
}

export default CreateReport;
