import { Container, FormControl, Typography, FormHelperText, Grow, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { StyledButton } from "../../../../shared/basic/StyledButton";
import { useWindowResize } from "../../../../utils/useWindowResize";

import CategorySelect from "../../../../shared/basic/CategorySelect";
import { useReportContext } from "../../../../shared/contexts/reportContext";
import { useStepsContext } from "../../../../shared/contexts/stepsContext";

const Categories = ({ setStep }) => {
    const history = useHistory();

    const { setCurrentStep, setMaxSteps } = useStepsContext();
    const { setReport, categories, isCategoryError } = useReportContext();

    const [selectedCategory, setSelectedCategory] = useState({});
    const [categoryMessage, setCategoryMessage] = useState('');

    const [nextStep, setNextStep] = useState();
    const [width] = useWindowResize();
    const { t, i18n: { language } } = useTranslation();

    const handleSelectCategory = (event, value) => {
        const { category_type } = categories.find(({ category_id }) => category_id === value);

        setSelectedCategory(value);
        setReport((data) => ({...data, category_id: value}));

        switch (category_type) {
            case 'anon':
                setNextStep(3);
            break;

            case 'non-anon':
                setNextStep(4);
            break;

            default:
                setNextStep(2);
            break;
        }
    };

    useEffect(() => {
        const category = categories.find(({ category_id }) => category_id === selectedCategory);

        if (category) {
            const { category_content } = category;

            if (category_content && category_content[language]) {
                setCategoryMessage(category_content[language]);
            } else {
                setCategoryMessage('');
            }
        }
    }, [language, selectedCategory, categories]);

    useEffect(() => {
        setCurrentStep(0);
        setMaxSteps(0);
    }, []);

    return (
        <Container
            maxWidth="xl"
            sx={{
                p: '64px 0',
                textAlign: 'center',
                borderRadius: '10px',
                backgroundColor: '#ffffff',
                boxShadow: '4px 8px 40px rgba(36, 63, 115, 0.08)'
            }}
        >
            <Typography variant="h2" sx={{ mb: '24px' }}>{t('categoriesPage.title')}</Typography>
            <Container maxWidth="lg" sx={{ mt: '24px' }}>

                <Container maxWidth="md">
                    <FormControl fullWidth {...(isCategoryError && { error: true })}>
                        <CategorySelect
                            categories={categories}
                            onChange={handleSelectCategory}
                            selected={selectedCategory}
                            {...(isCategoryError && { className: 'selCatError' })}
                        />
                        {
                            isCategoryError && <FormHelperText>{t('categoriesPage.error')}</FormHelperText>
                        }
                    </FormControl>
                </Container>

                <Box sx={{mt: '30px', mb: '15px', minHeight: '50px'}}>
                    <Grow in={!!categoryMessage}>
                        <Typography align="center">{categoryMessage}</Typography>
                    </Grow>
                </Box>

                <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'space-evenly', mt: '9%', flexWrap: 'wrap-reverse' }}>
                    <StyledButton
                        sx={{ m: '10px', textTransform: 'uppercase' }}
                        variant="outlined"
                        color="secondary"
                        size={width <= 900 ? "medium" : "large"}
                        onClick={() => history.push('/')}
                    >
                        {t('button.back')}
                    </StyledButton>
                    <StyledButton
                        sx={{ m: '10px', textTransform: 'uppercase' }}
                        disabled={!nextStep}
                        variant="contained"
                        size={width <= 900 ? "medium" : "large"}
                        onClick={() => setStep(nextStep)}
                    >
                        {t('button.confirm')}
                    </StyledButton>
                </Container>
            </Container>
        </Container>
    )
};

export default Categories;
