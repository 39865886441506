import { forwardRef} from 'react';
import { useTranslation } from "react-i18next";
import { CustomSelect, StyledOption } from './CustomizedSelectBase';

function RenderValue(option) {
    const { t } = useTranslation();

    if (!option) {
        return (<div>{t('complainReport.inputs.department.placeholder')}</div>);
    }

    return (<div className='categoty'>{option.label}</div>);
}

const DepartmentSelect = forwardRef((props, ref) => {
    const { field, departments, ...others } = props;

    return (
        <CustomSelect {...field} {...others} ref={ref} renderValue={RenderValue}>
            {departments.map((c) => (
                <StyledOption key={c.department_id} value={c.department_id}>
                    {c.department_name}
                </StyledOption>
            ))}
        </CustomSelect>
    );
})

export default DepartmentSelect;
