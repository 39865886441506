import axios from "axios";

export const _baseUrl = `//${window.location.hostname}/api/v1`;

const request = axios.create({
  baseURL: _baseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },

});

export const getCompany = () => {
  return request({
    method: "GET",
    url: "/company",
  });
};

export const getCategoriesList = () => {
  return request({
    method: "GET",
    url: '/categories',
  });
};

export const getReport = (key, secret) => {
  return request({
    method: "GET",
    url: `/report?access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`
  });
};

export const checkTokens = (key, secret) => {
  return request({
    method: "GET",
    url: `/check-token?access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`
  });
};

export const createReport = (reportData) => {

  return request({
    method: "POST",
    url: `/report`,
    data: JSON.stringify(reportData),
  });
};

export const addAttachment = (key, secret, attachments) => {
  const data = new FormData();
  attachments.forEach((file) => data.append('attachments[]', file))

  return request({
    method: "POST",
    url: `/report/attachments?access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data
  });
};

export const getMessages = (key, secret) => {
  return request({
    method: "GET",
    url: `/messages?access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`
  });
};

export const createMessage = (messageData, key, secret) => {
  const data = new FormData();
  data.append('content', messageData.content)
  messageData['attachments[]'].forEach((file) => data.append('attachments[]', file))

  return request({
    method: "POST",
    url: `/message?access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

export const getAttachment = (key, secret, attachmentId) => {
  return request({
    method: "GET",
    url: `/attachment?attachment_id=${attachmentId}&access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`,
  });
};

export const getAttachmentLink = (key, secret, attachmentId) => {
  return `${_baseUrl}/attachment?attachment_id=${attachmentId}&access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`
}

export const getPdfLink = (key, secret) => {
  return `${_baseUrl}/generate-pdf-token?access_key=${encodeURIComponent(key)}&access_secret=${encodeURIComponent(secret)}`
}

