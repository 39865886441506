import { Button, Divider, Typography, Box, Dialog, Container } from "@mui/material"
import { Message } from "./Message"
import SendIcon from '../../../icons/SendIcon';
import InputError from '../../../icons/InputError';
import AttachFileIcon from '../../../icons/AttachFileIcon';
import { useCallback, useEffect, useState } from "react";
import { createMessage, getMessages } from "../../../utils/apiCalls";
import { format } from 'date-fns'
import { FileDropzone } from "../../../shared/basic/FileDropzone"
import { StyledButton } from "../../../shared/basic/StyledButton";
import { FileChip } from "../../../shared/basic/FileChip";
import CustomizedTextField from "../../../shared/basic/CustomizedTextField";
import { useWindowResize } from "../../../utils/useWindowResize.js"
import { useTranslation } from "react-i18next";
import { getStoredKey } from "../../../utils/getStoredKey";
import { getStoredSecret } from "../../../utils/getStoredSecret";

export const MessageBox = ({ reporter, messages }) => {
    const [newMessage, setNewMessage] = useState(' ')
    const [allMessages, setAllMessages] = useState(messages)
    const [popup, setPopup] = useState(false)
    const [files, setFiles] = useState([])
    const [dropped, setDropped] = useState([])
    const [blocked, setBlocked] = useState(false)
    const [err, setErr] = useState()
    const [width] = useWindowResize()
    const { t } = useTranslation()

    const sendAndUpdateMessages = useCallback(async () => {
        setBlocked(true)
        try {
            if (newMessage || files) {
                await createMessage(
                    { content: newMessage.trim(), 'attachments[]': files },
                    getStoredKey(),
                    getStoredSecret()
                )
                let { data } = await getMessages(getStoredKey(), getStoredSecret())
                setNewMessage(' ')
                setErr('')
                setFiles([])
                setAllMessages(data?.data?.messages)
            }
        } catch (e) {
            if (typeof e.response.data === 'object') {
                setErr(t(`followReportPage.message-box.errors.${e.response.data.message.toLowerCase().split(' ').join('-')}`))
            } else {
                setErr(t('followReportPage.message-box.errors.default-error'))
            }
        }
        setBlocked(false)
    })

    useEffect(() => { setAllMessages(messages) }, [messages])

    useEffect(() => {
        if (dropped.length > 0) {
            for (let file of dropped) {
                if (file.errors) {
                    setBlocked(true)
                    break;
                }
                setBlocked(false)
            }
        } else {
            setBlocked(false)
        }
    }, [dropped, setDropped])

    const handleFileRemove = useCallback((index) => {
        const newList = files.filter((item) => item.id !== index)
        setFiles(newList);
    }, [files]);

    return (
        <>
            <Box sx={{ m: '1%', p: '24px 0', borderRadius: '15px', boxShadow: '4px 8px 40px 0px #243F7314' }}>
                <Typography variant="h4" sx={{ m: '0 24px 24px', textAlign: 'left' }}>{t('followReportPage.message-box.title')}</Typography>
                <Divider sx={{ width: '100%' }} />
                <Box sx={{ m: '0 24px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column-reverse', maxHeight: '400px', overflow: 'auto', m: '1% 0' }}>
                        {allMessages?.length > 0 ?
                            allMessages.map((item) =>
                                <Message
                                    isReporter={`${reporter?.first_name} ${reporter?.last_name}` === item?.message_sender}
                                    from={item?.message_sender}
                                    date={format(new Date(item?.created_at), 'dd.MM.yyyy HH:mm')}
                                    attachments={item?.attachments}
                                >{item?.content}</Message>
                            ).reverse()
                            :
                            <Box
                                sx={{
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                <Typography variant="title1_dimmed">{t('followReportPage.message-box.placeholders.title')}</Typography>
                                <Typography variant="bodyS_dimmed">{t('followReportPage.message-box.placeholders.subtitle')}</Typography>
                            </Box>
                        }
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <Typography variant="h4" sx={{ mb: '24px' }}>{t('followReportPage.message-box.title-text-field')}</Typography>
                        <CustomizedTextField
                            helperText={(!newMessage && t('followReportPage.message-box.errors.message-is-required')) || err}
                            onChange={(e) => setNewMessage(e.target.value)}
                            value={newMessage}
                            error={Boolean(!newMessage || err)}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '18px',
                                    pr: width <= 500 ? '18%' : '50px'
                                }
                            }}
                        />

                        <Button sx={{
                            position: 'absolute',
                            right: '1px',
                            border: 'none',
                            top: '22px',
                            padding: 0,
                            '&:hover, :focus': {
                                border: 'none',
                                color: 'transparent',
                            },
                        }}
                            size="small"
                            variant="outlined"
                            onClick={() => setPopup(true)}
                        >
                            <AttachFileIcon sx={{ fontSize: '25px' }} />
                            {!newMessage && <InputError sx={{ position: 'absolute', right: '55px', fontSize: '25px' }} />}
                        </Button>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {files.map((item) =>
                            <FileChip
                                style={{ maxWidth: '400px' }}
                                fileName={item.name}
                                fileId={item.id}
                                size={item.size}
                                type={item.name.split('.').pop().toUpperCase()}
                                removeFunc={handleFileRemove}
                            />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', mt: '1%', flexDirection: width <= 500 ? 'column' : 'row-reverse' }}>
                        <StyledButton
                            size={width <= 400 ? 'small' : 'medium'}
                            sx={{ m: '10px' }}
                            variant="contained"
                            disabled={blocked || !newMessage || newMessage === ' '}
                            onClick={() => sendAndUpdateMessages(newMessage)}
                        ><SendIcon style={{ fontSize: '20px' }} />&nbsp;{t('button.send').toUpperCase()}</StyledButton>
                    </Box>
                </Box>
            </Box>

            <Dialog open={popup}>
                <Container maxWidth="lg" sx={{ p: '54px 0', textAlign: 'center' }}>
                    <Typography variant="h2" align="center">{t('followReportPage.popup.title')}</Typography>
                    <Box m="auto" sx={{ width: '80%', mt: '24px', mb: '32px' }}><FileDropzone onDrop={setDropped} /></Box>
                    <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'space-evenly', mt: '9%', flexWrap: 'wrap' }}>
                        <StyledButton
                            variant="outlined"
                            color="secondary"
                            size={width <= 400 ? 'small' : 'medium'}
                            sx={{ m: '10px' }}
                            onClick={() => { setPopup(false); setDropped([]) }}
                        >{t('button.cancel').toUpperCase()}</StyledButton>
                        <StyledButton
                            variant="contained"
                            size={width <= 400 ? 'small' : 'medium'}
                            disabled={blocked}
                            sx={{ m: '10px' }}
                            onClick={() => { setPopup(false); setFiles([...files, ...dropped]); }}
                        >{t('button.save').toUpperCase()}</StyledButton>
                    </Container>
                </Container>
            </Dialog>
        </>
    )
}