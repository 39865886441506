import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { styled } from '@mui/system';
import { forwardRef } from 'react';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.additionalColors.stroke,
    fontSize: '25px',
    '&:hover': { color: theme.palette.mainColors.green },
    '&.Mui-checked': { color: theme.palette.mainColors.green },
    '&.Mui-disabled': { color: theme.palette.additionalColors.lightGrey }
}))

export const StyledCheckbox = forwardRef((props, ref) => {
    const {title} = props;
    return (
        < FormControlLabel
            control={< CustomCheckbox {...props} ref={ref} />}
            label={< Typography variant="bodyL_dimmed" >{title}</Typography >}
        />
    )
})