import { useState, useEffect } from "react";
import UserIdentity from "../../../../shared/forms/UserIdentity";
import { useReportContext } from "../../../../shared/contexts/reportContext";
import { useStepsContext } from "../../../../shared/contexts/stepsContext";
import { ComplainReport } from "../../../../shared/forms/ComplainReport";
import Confirmation from "../../../../shared/forms/Confirmation";


const NonAnonymous = ({ setStep }) => {
    const { setReport, report } = useReportContext();
    const { setCurrentStep, setMaxSteps } = useStepsContext();

    const [innerStep, setInnerStep] = useState(1);
    const [attachmentFile, setAttachmentFile] = useState();

    useEffect(() => {
        setCurrentStep(innerStep);
    }, [innerStep, setCurrentStep]);

    useEffect(() => {
        setMaxSteps(3);
    }, []);

    const reportStepContent = {
        1: <UserIdentity setStep={setStep} setInnerStep={setInnerStep} setReport={setReport}/>,
        2: <ComplainReport setStep={setStep} setInnerStep={setInnerStep} setReport={setReport} report={report} setAttachmentFile={setAttachmentFile}/>,
        3: <Confirmation setStep={setStep} setInnerStep={setInnerStep} report={report} attachmentFile={attachmentFile} step={innerStep}/>
    }
    return (
        <>
            {reportStepContent[innerStep]}
        </>
    )
}

export default NonAnonymous;
