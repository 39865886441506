import { AppBar, Button, Toolbar, Typography, Box } from "@mui/material"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { history } from "../../App"
import { useTheme } from '@mui/material/styles';
import CustomizedSteppers from "./CustomizedSteppers"
import { useStepsContext } from "../contexts/stepsContext";
import Logo from '../../features/images/Logo.png'
import { useTranslation } from "react-i18next"
import { setStoredTokens } from "../../utils/setStoredTokens"
import { LanguageSelect } from "./LanguageSelect"


export const Header = () => {
    const theme = useTheme();
    const location = useLocation();

    const { currentStep, maxSteps } = useStepsContext();

    const { t } = useTranslation()

    return (
        <AppBar position="static"
            sx={{
                minHeight: '88px',
                backgroundColor: 'white',
                boxShadow: 'none',
                [theme.breakpoints.down('md')]: {
                    minHeight: '134px',
                }
            }}>
            <Toolbar sx={{
                display: 'flex', justifyContent: 'space-between', height: '88px', alignContent: ' spaceAround',
                [theme.breakpoints.down('md')]: {
                    flexWrap: 'wrap',
                },
            }}>

                <Box sx={{
                    [theme.breakpoints.down('md')]: {
                        flexBasis: '50%',
                        flexGrow: 1,
                    },
                }}
                >
                    <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                        <Typography variant="h6" color="#2C291F" component="div" sx={{ display: 'flex' }}>
                            <img src={Logo} alt="Logo" style={{ height: '40px', minWidth: '175px' }} />
                        </Typography>
                    </Link>
                </Box>
                <Box
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            order: 3,
                            flexGrow: 1,
                            pt: '30px',
                            ml: '-3%'
                        },
                    }}
                >
                    {location.pathname === '/create-report' && currentStep && <CustomizedSteppers step={currentStep} maxSteps={maxSteps} />}
                </Box>
                <Box sx={{
                    [theme.breakpoints.down('md')]: {
                        flexBasis: '50%',
                        justifyContent: 'flex-end',
                        display: 'flex',
                    },
                    [theme.breakpoints.down('md')]: {
                        flexShrink: 2,
                    }
                }}
                >
                    {location.pathname === '/follow-report' ?
                        <Button variant="outlined"
                            onClick={() => {
                                setStoredTokens()
                                history.push('/');
                            }}
                            sx={{
                                backgroundColor: `${theme.palette.mainColors.yellow}`,
                                color: `${theme.palette.mainColors.white}`,
                                height: '35px',
                                width: '113px',
                                ':hover': {
                                    backgroundColor: `${theme.palette.action.hoverPrimary}`,
                                },
                                marginRight: '15px'
                            }}
                        >{t('button.log-out')}</Button> :
                        <Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
                            <Button variant="outlined"
                                sx={{
                                    backgroundColor: `${theme.palette.mainColors.yellow}`,
                                    color: `${theme.palette.mainColors.white}`,
                                    height: '35px',
                                    width: '102px',
                                    ':hover': {
                                        backgroundColor: `${theme.palette.action.hoverPrimary}`,
                                    },
                                    marginRight: '15px'
                                }}>{t('button.log-in')}</Button>
                        </Link>
                    }
                    <LanguageSelect />
                </Box>
            </Toolbar>
        </AppBar>
    )
}
