import { useEffect, useState, useMemo } from "react";
import { Container, Typography } from "@mui/material";
import { StyledButton } from "../../../../shared/basic/StyledButton";
import { useHistory } from "react-router-dom";
import { useWindowResize } from "../../../../utils/useWindowResize";
import { useTranslation } from "react-i18next";
import { useCompanyContext } from "../../../../shared/contexts/companyContext";

const Welcome = () => {
    const history = useHistory();
    const { companyName, companyContent } = useCompanyContext();

    const { t, i18n: { language } } = useTranslation();
    const [width] = useWindowResize();
    const [orgName, setOrgName] = useState('Name');

    const content = useMemo(() => {
        return companyContent[language] ? companyContent[language] : false;
    }, [language, companyContent]);

    useEffect(() => {
        setOrgName(companyName);
    }, [companyName]);

    return (
        <Container maxWidth="md" sx={{ mt: '8%', textAlign: 'center' }}>
            <Typography variant={width <= 900 ? "h2" : "h1"}>
                {t('mainPage.title.text')} <Typography variant={width <= 900 ? "h2_accent" : "h1_accent"}>{t('mainPage.title.text-accent')}</Typography>
            </Typography>
            {content && (
                <Typography
                    variant={width <= 900 ? "subtextH2" : "subtextH1"}
                    align="center"
                    component="div"
                    m="auto"
                    sx={{ width: '80%', mt: '51px', mb: '51px', '& a': { color: 'additionalColors.grey' } }}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            )}
            <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'space-evenly', mt: '9%', flexWrap: 'wrap' }}>
                <StyledButton
                    sx={{ m: '10px' }}
                    variant="contained"
                    size={width <= 900 ? "medium" : "large"}
                    onClick={() => history.push('/create-report')}
                >{t('button.create-new-report')}</StyledButton>
                <StyledButton
                    sx={{ m: '10px', textTransform: 'uppercase' }}
                    variant="outlined"
                    color="secondary"
                    size={width <= 900 ? "medium" : "large"}
                    onClick={() => history.push('/login')}
                >{t('button.see-your-inbox')}</StyledButton>
            </Container>
        </Container>
    )
}

export default Welcome;
