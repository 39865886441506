import { Box, FormControlLabel, Radio, Typography } from "@mui/material"
import { styled } from '@mui/system';
import theme from '../../styles/theme.js'
import RadioIconBold from "../../icons/RadioIconBold.jsx";

const CustomRadio = styled(Radio)(({ theme }) => ({
    color: theme.palette.additionalColors.stroke,
    fontSize: '25px',
    '&:hover': { color: theme.palette.mainColors.green },
    '&.Mui-checked': { color: theme.palette.mainColors.green },
}))

export const StyledRadio = ({ title, subtitle, background = 'transparent', ...props }) => {
    return (
        <Box
            {...props}
            sx={{
                textAlign: 'left',
                border: `1px solid ${theme.palette.additionalColors.stroke}`,
                borderRadius: '10px',
                p: ' 16px 27px',
                m: '5px',
                transition: '0.1s',
                backgroundColor: background
            }}
        >
            <FormControlLabel
                control={<CustomRadio size="small" checkedIcon={<RadioIconBold sx={{ fontSize: '30px' }} />} />}
                label={
                    <Box>
                        <Typography variant="title2">{title}</Typography>
                        <br />
                        <Typography variant="bodyL_dimmed">{subtitle}</Typography>
                    </Box>
                }
                {...props}
            />
        </Box>
    )
}