import { Container, FormControl, RadioGroup, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../../../../shared/basic/StyledButton";
import { StyledRadio } from "../../../../shared/basic/StyledRadio";
import theme from "../../../../styles/theme";
import { useWindowResize } from "../../../../utils/useWindowResize";
import { useStepsContext } from "../../../../shared/contexts/stepsContext";


const Question = ({ setStep }) => {
    const { setCurrentStep, setMaxSteps } = useStepsContext();

    const [nextStep, setNextStep] = useState();
    let [checked, setChecked] = useState();
    let [width] = useWindowResize();
    const { t } = useTranslation();

    useEffect(() => {
        setCurrentStep(0);
        setMaxSteps(0);
    }, []);

    return (
        <Container
            maxWidth="xl"
            sx={{
                p: '64px 0',
                textAlign: 'center',
                borderRadius: '10px',
                backgroundColor: '#ffffff',
                boxShadow: '4px 8px 40px rgba(36, 63, 115, 0.08)'
            }}
        >
            <Typography variant="h2" sx={{ mb: '24px' }}>{t('questionPage.title')}</Typography>
            <Typography variant="subtextH2" align="center" sx={{ width: '80%', mb: '24px' }}>
                {t('questionPage.subtitle')}
            </Typography>
            <Container maxWidth="lg" sx={{ mt: '24px' }}>
                <FormControl>
                    <RadioGroup name="radio-buttons-group">
                        <StyledRadio
                            title={t('questionPage.radio.no.title')}
                            subtitle={t('questionPage.radio.no.subtitle')}
                            background={checked === 1 ? theme.palette.additionalColors.lightGreen : 'transparent'}
                            value={false}
                            onChange={() => { setNextStep(3); setChecked(1) }}
                        />
                        <StyledRadio
                            title={t('questionPage.radio.yes.title')}
                            subtitle={t('questionPage.radio.yes.subtitle')}
                            background={checked === 2 ? theme.palette.additionalColors.lightGreen : 'transparent'}
                            value={true}
                            onChange={() => { setNextStep(4); setChecked(2) }}
                        />
                    </RadioGroup>
                </FormControl>
                <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'space-evenly', mt: '9%', flexWrap: 'wrap-reverse' }}>
                    <StyledButton
                        sx={{ m: '10px', textTransform: 'uppercase' }}
                        variant="outlined"
                        color="secondary"
                        size={width <= 900 ? "medium" : "large"}
                        onClick={() => setStep(1)}
                    >
                        {t('button.back')}
                    </StyledButton>
                    <StyledButton
                        sx={{ m: '10px', textTransform: 'uppercase' }}
                        disabled={!nextStep}
                        variant="contained"
                        size={width <= 900 ? "medium" : "large"}
                        onClick={() => setStep(nextStep)}
                    >
                        {t('button.confirm')}
                    </StyledButton>
                </Container>
            </Container>
        </Container>
    )
};

export default Question;
