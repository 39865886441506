import { SvgIcon } from "@mui/material";

const Download = ({ color, format, ...props }) => (
    <SvgIcon {...props} width="58" height="58" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29" cy="29" r="28.5" stroke={color || "#ABBAAC"} strokeDasharray="3 3" />
        <path d="M29 24.5L24.5 29.6562L27.875 29.6562L27.875 41H30.125L30.125 29.6562H33.5L29 24.5Z" fill={color || "#EDCA4B"} />
        <path d="M22 33.5H26V31.4231L22 31.4231L22 22.0769L36 22.0769V31.4231L32 31.4231V33.5H36C37.103 33.5 38 32.5685 38 31.4231V22.0769C38 20.9315 37.103 20 36 20L22 20C20.897 20 20 20.9315 20 22.0769L20 31.4231C20 32.5685 20.897 33.5 22 33.5Z" fill={color || "#EDCA4B"} />
    </SvgIcon>
)

export default Download;