import { Typography, Select, MenuItem } from "@mui/material"
import i18next from "i18next"

export const LanguageSelect = () => {
    return (
        <Select
            variant="outlined"
            onChange={(e) => i18next.changeLanguage(e.target.value)}
            value={i18next.language}
            color="primary"
            size='small'
        >
            {Object.keys(i18next.options.resources).map((language) =>
                <MenuItem key={language} value={language}><Typography variant="subtitle2">{language}</Typography></MenuItem>
            )}
        </Select>
    )
}
