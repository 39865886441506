import { TextField, InputAdornment } from '@mui/material';
import { forwardRef } from 'react';
import { styled } from '@mui/system';
import InputError from '../../icons/InputError';

const MyInput = styled(TextField)(({ theme }) => ({
  '.MuiFormControl-root': {
    borderRadius: '10px',
  },
  
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${theme.palette.additionalColors.lightGreen}`,
    fontSize: '20px',
    borderRadius: '10px',
    paddingRight: '35px',
    fontFamily:  `${theme.typography.title1.fontFamily}`,
   
    '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.mainColors.green}`,
    },
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.mainColors.green}`,
    },
    '& svg': {
      fontSize: '26px',
    },
    '& input': {
      '&::placeholder': {
        color: `${theme.palette.additionalColors.olive}`,
        opacity: 1,
      },
    }, 
    '& textarea': {
      '&::placeholder': {
        color: `${theme.palette.additionalColors.olive}`,
        opacity: 1,
      }  
    }, 
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.additionalColors.stroke}`,
  },
  '& .MuiOutlinedInput-root.Mui-error': {
    backgroundColor: '#F9D5CC',
    '&.Mui-focused fieldset, &:hover fieldset.MuiOutlinedInput-notchedOutline ': {
      border: `1px solid #EA4E4E`,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      border: `1px solid #EA4E4E`,
    },
  },
  '& .MuiOutlinedInput-root.Mui-error ::placeholder': {
    color: '#5C5B53',
  },
  '& .MuiOutlinedInput-input': {
    '&:-webkit-autofill': {
      'WebkitBoxShadow': `0 0 0 100px ${theme.palette.additionalColors.lightGreen} inset`,
    },
    '&.MuiOutlinedInput-input': {
      padding: '30px 35px 29px 32px',
      lineHeight: '145%',
      fontSize: '20px',
      color: `${theme.palette.additionalColors.black}`,
    },
  },
}));

const CommonInput = forwardRef((props, ref) => {

  return (
    <MyInput
      ref={ref}
      {...props}
      InputProps={{
        endAdornment: props.error ?
          <InputAdornment position='end'>{<InputError /> && props.staticicon}</InputAdornment>
          :
          <InputAdornment position='end'>{props.staticicon || ''}</InputAdornment>
      }}
    />
  )
});

export default CommonInput;