import React, { useContext, useState } from 'react';

const StepsContext = React.createContext({});

export const StepsProvider = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [maxSteps, setMaxSteps] = useState(0);

    return (
      <StepsContext.Provider
        value={{
            currentStep,
            setCurrentStep,
            maxSteps,
            setMaxSteps
        }}
      >
        {children}
      </StepsContext.Provider>
    );
  };

export const useStepsContext = () => useContext(StepsContext);

export default StepsContext;
