import { forwardRef } from 'react';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/system';
import { PopperUnstyled } from '@mui/base';

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: ${theme.typography.title1.fontFamily};
  font-size: 20px;
  box-sizing: border-box;
  // height: calc(0em + 56px);

  ${[theme.breakpoints.down('md')]} {
    min-width: 114px;
    padding: 30.88px 29.25px 30.87px 24.63px;
  }

  ${[theme.breakpoints.up('md')]} {
    min-width: 172px;
    padding: 30.88px 23.25px 30.87px 24.62px;
  }

  height: 88px;
  border: 1px solid ${theme.palette.additionalColors.stroke};
  border-radius: 10px;
  margin: 0em;
  text-align: left;
  line-height: 145%;
  color: ${theme.palette.additionalColors.olive};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: ${theme.palette.additionalColors.lightGreen};
    border-color: ${theme.palette.mainColors.green};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: url("data:image/svg+xml;charset=UTF-8, <svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg' version='1.1'><path d='M1.75 8.62112L6.043 4.32812L7.043 3.41406L8.043 4.32812L12.336 8.62112L13.75 7.20712L7.043 0.500122L0.335999 7.20712L1.75 8.62112Z' fill='%23807E78'/></svg>");
    }
  }

  &::after {
    float: right;
    content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='14' height='9' viewBox='0 0 14 9' fill='none'><path d='M1.75 0.378877L6.043 4.67188L7.043 5.58594L8.043 4.67188L12.336 0.378878L13.75 1.79288L7.043 8.49988L0.335999 1.79288L1.75 0.378877Z' fill='%23807E78'/></svg>");
  }

  & img {
    margin-right: 6.5px;
    width: 36.75px;
    height: 26.25px;
    ${[theme.breakpoints.down('md')]} {
      margin-right: 16px;
    }
  }

  & {
    background: ${theme.palette.additionalColors.lightGreen};
  }

  &.selCatError {
    background: ${theme.palette.additionalColors.lightGrey};
    border: 1px solid #EA4E4E;
  }

  & div {
    display: flex;
  }

  & .categoty {
   color: ${theme.palette.additionalColors.black};
    }
  `
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: ${theme.typography.title1.fontFamily};
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px 0px;
  min-width: 666px;
  max-height: 325px;

  ${[theme.breakpoints.down('md')]} {
    min-width: 335px;
  }

  background: ${theme.palette.mainColors.white};
  border: none;
  border-radius: 10px;
  color: ${theme.palette.additionalColors.grey};
  overflow: auto;
  outline: 0px;
  font-size: 20px;

  &::-webkit-scrollbar {
    background-color: ${theme.palette.additionalColors.stroke};
    width: 7px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.mainColors.green};
    border-radius: 72px;
    width: 7px; margin: 15px;
  }
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 17.5px 4.69px 17.5px 17px;
  border-radius: 0px;
  cursor: default; margin-right: 7.7px;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 6px;
  }
  &:first-of-type {
    padding-top: 5.5px;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mainColors.white};
    color: ${theme.palette.additionalColors.grey};
    font-size: 20px;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.additionalColors.lightGreen};
    color: ${theme.palette.additionalColors.black};
    font-size: 20px;
    border-radius: 7px;

  }

  & img {
    margin-right: 10px;
    padding-left: 0px;
    width: 28px;
    height: 20px;
  }
  `
);

const StyledPopper = styled(PopperUnstyled)(
  ({ theme }) => `
    z-index: 1;
    padding: 15px 14.69px 15px 5.31px;
    border: 1px solid ${theme.palette.additionalColors.stroke};
    border-radius: 10px;
    background-color: white;
  `
);

const CustomSelect = forwardRef((props, ref) => {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} ref={ref} slots={slots} />;
});

export { CustomSelect, StyledOption };
