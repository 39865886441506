import { Typography, Container, Box, FormControl, FormHelperText } from "@mui/material"
import { CustomInput } from "../basic/CustomInput.jsx";
import { FileDropzone } from "../basic/FileDropzone.jsx";
import { useForm, Controller } from "react-hook-form"
import { useWindowResize } from "../../utils/useWindowResize.js"
import { useState, useEffect, useCallback, useMemo } from "react";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { StyledButton } from "../basic/StyledButton.jsx";
import CommonInput from "../basic/CommonInput.jsx";
import DepartmentSelect from "../basic/DepartmentSelect.jsx";
import { useTranslation } from "react-i18next";
import { useCompanyContext } from "../contexts/companyContext.jsx";

export const ComplainReport = ({ setStep, setInnerStep, setReport, setAttachmentFile }) => {
    const { t } = useTranslation();

    const { departments } = useCompanyContext();

    const errorMessage = {
        subject: t('complainReport.inputs.subject.errors.required'),
        description: t('complainReport.inputs.description.errors.required'),
        department_id: t('complainReport.inputs.department.errors.required'),
    }

    const complainReportSchema = useMemo(() => {
        const rules = {
            subject: yup.string().required(),
            description: yup.string().required(),
        };

        if (departments && departments.length > 1) {
            rules['department_id'] = yup.number().integer().required();
        }

        return yup.object().shape(rules);
    }, [departments]);

    const { control, handleSubmit, formState: { errors, isValid }, getValues, watch } = useForm({
        resolver: yupResolver(complainReportSchema),
        mode: 'onChange'
    });
    const [files, setFiles] = useState([]);
    const [sizeFile, setSizeFile] = useState(2000000);
    const [width] = useWindowResize()

    const onSubmit = useCallback((data) => {
        const { subject, description, department_id } = data;
        setReport((formNonAnonymously) => ({
            subject,
            description,
            department_id,
            ...formNonAnonymously,
        }));
        setAttachmentFile(files);
        setInnerStep(3);
    }, [files]);

    const formFields = [
        {
            display: departments && departments.length > 1 ? true : false,
            name: 'department_id',
            title: t('complainReport.inputs.department.title'),
            subtitle: '',
            defaultValue: '',
            render: ({ field }) => (
                <FormControl
                    fullWidth
                    {...(errors.departments && { error: true })}
                >
                    <DepartmentSelect
                        {...field}
                        departments={departments}
                        onChange={(e, v) => field.onChange(v)}
                        {...(errors.departments && { className: 'selCatError' })}
                    />
                    {
                        errors.departments && <FormHelperText>{t('complainReport.inputs.department.errors.required')}</FormHelperText>
                    }
                </FormControl>
            )
        },
        {
            display: departments && departments.length === 1 ? true : false,
            name: 'department_id',
            title: t('complainReport.inputs.department.title'),
            subtitle: '',
            defaultValue: departments[0].department_id,
            render: ({ field }) => (
                <Box sx={{
                    backgroundColor: 'additionalColors.lightGrey',
                    fontFamily: 'inherit',
                    p: '30px 35px 29px 32px',
                    lineHeight: '145%',
                    fontSize: '20px',
                    color: 'bodyL.color',
                    borderRadius: '10px',
                    border: '1px solid',
                    borderColor: 'additionalColors.stroke'
                }}>
                    {departments[0].department_name}
                </Box>
            )
        },
        {
            display: true,
            name: 'subject',
            title: t('complainReport.inputs.subject.title'),
            subtitle: '',
            defaultValue: '',
            render: ({ field }) =>
                <CommonInput
                    id="outlined-basic" placeholder={t('complainReport.inputs.subject.placeholder')} fullWidth {...field}
                    {...(errors.subject && { error: true, helperText: errorMessage.subject })}
                    sx={{ '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } }}
                />
        },
        {
            display: true,
            name: 'description',
            title: t('complainReport.inputs.description.title'),
            subtitle: t('complainReport.inputs.description.subtitle'),
            defaultValue: '',
            render: ({ field }) =>
                <CommonInput
                    multiline rows={5}
                    fullWidth placeholder={t('complainReport.inputs.description.placeholder')} {...field}
                    {...(errors.description && { error: true, helperText: errorMessage.description })}
                    sx={{ '& .MuiOutlinedInput-root': { padding: '0', fontSize: '5px' } }}
                />
        },
        {
            display: true,
            name: 'uploadFile',
            title: t('complainReport.inputs.upload-relevant-files.title'),
            subtitle: t('complainReport.inputs.upload-relevant-files.subtitle'),
            defaultValue: [],
            render: ({ field: { onChange } }) =>
                <FileDropzone
                    onDrop={onChange}
                    sizeFile={sizeFile}
                />
        },
    ];

    useEffect(() => {
        const maxSizeFiles = files.reduce((accum, item) => {
            return accum - item.size
        }, 2000000);
        setSizeFile(maxSizeFiles);
    }, [files])

    useEffect(() => {
        const watchFiles = watch("uploadFile");
        return () => watchFiles.unsubscribe;
    }, [watch]);

    const uploadFile = getValues('uploadFile') || [];
    useEffect(() => {
        setFiles(uploadFile);
    }, [uploadFile]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
                padding: '64px 0',
                borderRadius: '10px',
                boxShadow: '4px 8px 40px rgba(36, 63, 115, 0.08)'
            }}
        >
            <Container maxWidth="md">
                <Box>
                    <Typography variant="h2" sx={{ textAlign: 'center', mb: '64px' }}>{t('complainReport.title')}</Typography>

                    {
                        formFields
                            .filter(({display}) => display)
                            .map((field, index, array) => {
                                return <CustomInput
                                    key={field.name}
                                    sup={`${index + 1} / ${array.length}`}
                                    name={field.title}
                                    sub={field.subtitle}
                                >
                                    <Controller
                                        render={field.render}
                                        name={field.name}
                                        control={control}
                                        defaultValue={field.defaultValue}
                                        className="materialUIInput"
                                    />
                                </CustomInput>;
                            })
                    }

                    <Container maxWidth="md" sx={{ display: "flex", justifyContent: 'space-evenly', width: '90%', mt: '2%', flexWrap: 'wrap-reverse' }}>
                        <StyledButton
                            variant="outlined"
                            color="secondary"
                            type="button"
                            size={width <= 900 ? "medium" : "large"}
                            sx={{ m: '10px', textTransform: 'uppercase' }}
                            onClick={() => setStep(1)}
                        >{t('button.back')}</StyledButton>
                        <StyledButton
                            variant="contained"
                            disabled={!isValid}
                            type="submit"
                            size={width <= 900 ? "medium" : "large"}
                            sx={{ m: '10px', textTransform: 'uppercase' }}
                        >{t('button.next-step')}</StyledButton>
                    </Container>
                </Box>
            </Container>
        </form >
    )
}
